<template>
    <div :id="'chat-room-'+roomInfo.id" class="chat-room" :class="{ minimized: isMinimized, expanded: canManageUsers}">
        <header class="chat-room-header" ref="header" v-on="isMinimized ? { click: unMinimize } : {}" @keyup.esc="disableManageMode" tabindex="-1" v-click-outside-with-exclusion="{exclude: ['header', 'headerInfo', 'manageMembers', 'removeIcon'], handler: 'disableManageMode'}">
            <div v-if="isEditableView" class="select-recipients">
                <h4 class="heading4">{{ manageModeHeading }}</h4>
                <multiselect
                    track-by="id"
                    class="multiselect--complex multiselect-mid"
                    v-model="selectedRecipients"
                    :options="recipients"
                    placeholder="Enter recipient"
                    @search-change="getAvailableRecipients"
                    open-direction="bottom"
                    :loading="isLoading"
                    :multiple="true"
                    :internal-search="false"
                    :hide-selected="true"
                    :blockKeys="['Delete']"
                >
                    <template slot="noResult">
                        <div>
                            <h5>No recipient found</h5>
                            <p> Try adjusting your search.</p>
                        </div>
                    </template>
                    <template slot="selection" slot-scope="{ values }">
                        <div class="multiselect__tags-wrap">
                            <span class="multiselect__tag" v-for="(option, index) in values" :key="index">
                                <span :class="option.type">{{ option.name }}</span>
                                <i v-show="selectedRecipients.length > 1 || isNewChatRoom" ref="removeIcon" aria-hidden="true" tabindex="1" class="multiselect__tag-icon" @click="removeRecipient(option)"></i>
                            </span>
                        </div>
                    </template>
                    <template slot="option" class="anton" slot-scope="{ option }">
                        <div class="available-option" :class="'multiselect__element__' + option.company_type">
                            <div class="image-block">
                                <img :src="LogoHelper.getLogo(option.type == 'user' ? option.logo : option.avatar)"
                                     alt="">
                            </div>
                            <div>
                                <h4 class="heading4">{{ option.company }}</h4>
                                <p>{{ option.name + (option.job_title ? " " + option.job_title : "") }}</p>
                            </div>
                        </div>
                    </template>
                </multiselect>

                <b-modal id="modal-duplicate-chats" title="Duplicate Chats" no-close-on-backdrop no-close-on-esc hide-header-close>
                    <p class="text-title">
                        A chat with these participants already exists. You will be directed to that chat.
                    </p>
                    <template #modal-footer>
                        <b-button v-if="false" size="lg" variant="outline-primary" class="btn btn-bordered btn-lg" @click="addRecipientToThisChat">
                            Modify This Chat
                        </b-button>
                        <b-button size="lg" variant="primary" class="ml-3" @click="openMatchedChat">
                            Ok
                        </b-button>
                    </template>
                </b-modal>
            </div>

            <div v-show="!isEditableView" class="chat-room-header-info" ref="headerInfo" v-on="canManageUsers ? {click:enableManageMode} : {}">
                <div class="image-block">
                    <img :src="LogoHelper.getLogo(roomInfo.logo)" alt="">
                </div>
                <div>
                    <h4 class="heading4">{{ roomInfo.company_name }}</h4>
                    <p>
                        <span class="chat-room-header-info__name">
                            {{ chatMembers }}
                        </span>
                        &nbsp;
                        <span v-if="typeof roomInfo.last_activity_ago !== 'undefined'" class="chat-room-header__activity">Online <template v-if="roomInfo.last_activity_ago.length"> {{ roomInfo.last_activity_ago }} ago</template></span>
                    </p>
                </div>
            </div>

            <div class="chat-room-header-actions">
                <b-dropdown v-show="!isEditableView" id="dropdown-1" text="" class="drag-n-drop icons-list dropdown-simple" :class="{ hide: isMinimized}">
                    <b-dropdown-item class="action icons-list__info" @click="$refs.chatDetails.open()">
                        View Chat Details
                    </b-dropdown-item>
                    <b-dropdown-item v-if="canManageUsers" ref="manageMembers" class="action icons-list__user" @click="enableManageMode">
                        Manage Members
                    </b-dropdown-item>

                    <b-dropdown-item v-if="canLeaveGroupChat" class="action icons-list__leave" @click="$bvModal.show('modal-leave-chat')">
                        Leave Chat
                    </b-dropdown-item>
                </b-dropdown>

                <chat-details ref="chatDetails"
                              :roomInfo="roomInfo"
                              v-on="$listeners"
                ></chat-details>

                <b-modal id="modal-leave-chat" title="Leave Group?" no-close-on-backdrop>
                    <p class="text-title">
                        This action can't be undone.
                    </p>
                    <template #modal-footer>
                        <b-button size="lg" variant="outline-primary" class="btn btn-bordered btn-lg" @click="leaveChatModalHide">
                            Cancel
                        </b-button>
                        <b-button size="lg" variant="primary" class="ml-3" @click="leaveChat">
                            Leave Group
                        </b-button>
                    </template>
                </b-modal>

                <a v-if=" false && !isNewChatRoom" href="#" class="chat-room__open-modal"></a>
                <button v-if="!isEditableView" type="button" class="minimize" @click="minimizeRoom"
                        :class="{ hide: isMinimized}"></button>
                <button type="button" class="close" @click="closeRoom"></button>
            </div>
        </header>
        <div class="chat-room-content">
            <b-button v-if=" false && isManageChatRoomMode" size="lg" variant="outline-primary" class="btn btn-bordered btn-lg" @click="saveChatUpdates">
                Save
            </b-button>

            <div class="chat" ref="scrollContainer">
                <p v-if="false" class="chat__left">Julia Budnik Left the group.</p>

                <chat-message v-for="(message, index) in messages"
                              :key="message.id"
                              :index="index"
                              :messages="messages"
                              :message="message"
                              :logo="roomInfo.logo"
                >
                </chat-message>
            </div>

            <div class="chat-room-footer" v-if="showMessageInput">
                <textarea
                    id="roomTextarea"
                    ref="roomTextarea"
                    placeholder="Enter a message"
                    class="message-input"
                    v-model="newMessage"
                    @keydown.enter.exact.prevent
                    @keyup.enter.exact.prevent="sendMessageByEnter"
                    @focus="markAsRead"
                />

                <b-button class="button-send" @click="sendMessage" size="sm" :disabled="!canSend"></b-button>
            </div>
        </div>
    </div>
</template>

<script>
    import chatsService from '@/services/chats.service'
    import {mapMutations} from "vuex";

    export default {
        name: 'ChatRoom',
        components: {
            multiselect: () => import("vue-multiselect"),
            ChatMessage: () => import("@/views/messages/ChatMessage"),
            ChatDetails: () => import("@/views/messages/ChatDetailsModal"),
        },
        props: {
            roomInfo: {type: Object, required: true},
        },
        data() {
            return {
                initiateMessages: true,
                messages: [],
                newMessage: '',
                isMinimized: this.roomInfo.isMinimized === false,

                recipients: [],
                selectedRecipients: this.roomInfo.selectedRecipients ? this.roomInfo.selectedRecipients : this.getFormattedMembers(this.roomInfo.members),
                isLoading: false,
                isNewChatRoom: this.roomInfo.type === 'new',
                isManageChatRoomMode: false,
                matchedChatRoom: null,

                loadingMessages: false,
                loadMessagesTriggerPoint: null,

                sendingMessage: false,

                keepMessages: false,
            }
        },
        mounted() {
            if (!this.isNewChatRoom && this.initiateMessages) {
                this.initChatMessages()
            }
        },
        watch: {
            selectedRecipients: {
                immediate: true,
                handler(newValue, oldValue) {
                    if (this.isEditableView && this.selectedRecipients.length) {
                        let data = this.getSelectedRecipientsGroupByTypes()

                        chatsService.getChatByMembers(data.users, data.groups).then(response => {
                            if (response.data) {
                                this.matchedChatRoom = response.data

                                if (this.isNewChatRoom) {
                                    this.messages = response.data.messages

                                }

                                if (this.isManageChatRoomMode) {
                                    newValue.length > oldValue.length ? this.duplicateChatsModalShow() : this.saveChatUpdates()
                                }
                            } else {
                                this.matchedChatRoom = null

                                if (this.isNewChatRoom) {
                                    this.messages = []
                                } else {
                                    //Save on select. Only for existing chat
                                    // Remove if decide to use button.
                                    this.saveChatUpdates()
                                }
                            }
                        })
                    } else {
                        this.matchedChatRoom = null

                        if (!this.keepMessages) {
                            this.messages = []
                        }
                    }
                }
            },
            messages: {
                handler() {
                    if (!this.isNewChatRoom) {
                        setTimeout(() => {
                            this.loadMessagesTriggerPoint = this.getTopScroll(this.$refs.scrollContainer)
                        }, 50)
                    }
                },
                deep: true
            },
            roomInfo: {
                handler(newValue, oldValue) {
                    // keep tracking just when member is deleted
                    if (newValue.members.length < oldValue.members.length) {
                        this.keepMessages = true
                        this.selectedRecipients = this.getFormattedMembers(newValue.members)
                    }
                },
                deep: true
            },
        },
        computed: {
            canSend() {
                return !this.sendingMessage && this.newMessage.trim()
            },
            chatMembers() {
                let chatMembers = ""

                if (!this.isNewChatRoom) {
                    chatMembers = this.roomInfo.members[0].name

                    if (this.roomInfo.members.length > 1) {
                        chatMembers += ", +" + (this.roomInfo.members.length - 1)
                    }
                }

                return chatMembers
            },
            canLeaveGroupChat() {
                return !this.roomInfo.is_admin && this.roomInfo.members && this.roomInfo.members.length > 1
            },
            canManageUsers() {
                return this.roomInfo.is_admin && !this.isMinimized
            },
            isEditableView() {
                return this.isNewChatRoom || this.isManageChatRoomMode
            },
            manageModeHeading() {
                return this.isNewChatRoom ? 'New Message' : 'Manage Users'
            },
            showMessageInput() {
                return !this.isManageChatRoomMode && (this.selectedRecipients.length || !this.isNewChatRoom)
            }
        },
        methods: {
            ...mapMutations(['refreshLastMessageSent']),
            
            initChatMessages() {
                chatsService.getMessages(this.roomInfo.id).then(response => {
                    this.messages = response.data
                    this.$refs.scrollContainer.addEventListener('scroll', this.onContainerScroll)
                })
            },

            sendMessageByEnter() {
                if (!this.canSend) return
                this.sendMessage()
            },

            manageChat(chatId = 0, data, message = "") {
                if (!chatId && !message) return // message is required for a new chat

                // when chatId = 0, it means create new chat, otherwise update chat that already exist by its id
                chatsService.createChat(chatId, data).then(response => {
                    let chatData = response.data

                    if (chatId) {
                        chatData.last_message_sent = this.dateHelper.now('Y-M-D HH:mm:ss')
                        this.disableManageMode()
                        this.$emit('updateRoom', this.roomInfo.id, chatData)
                    } else {
                        chatsService.sendMessage(chatData.id, message).then(response => {
                            if (response.data.status) {
                                this.newMessage = ""
                            }
                            this.sendingMessage = false
                            this.isNewChatRoom = false

                            this.$emit('chatCreated', this.roomInfo.id, chatData)
                        })
                    }
                })

                return true
            },

            getSelectedRecipientsGroupByTypes() {
                return {
                    users: this.selectedRecipients.filter(recipient => recipient.type === 'user').map(item => item.id),
                    groups: this.selectedRecipients.filter(recipient => recipient.type === 'group').map(item => item.id),
                }
            },

            sendMessage() {
                let message = this.newMessage.trim()

                if (!message) return
                this.sendingMessage = true

                if (this.isNewChatRoom) {
                    if (!this.matchedChatRoom) {
                        let data = this.getSelectedRecipientsGroupByTypes()

                        if (data.users.length || data.groups.length) {
                            return this.manageChat(0, data, message)
                        }

                        return false
                    } else {
                        chatsService.sendMessage(this.matchedChatRoom.id, message).then(() => {
                            this.$emit('updateRoom', this.roomInfo.id, this.matchedChatRoom)
                        });
                    }
                } else {
                    let lastMessageId = 0

                    if (this.messages.length) {
                        lastMessageId = this.messages[0].id
                    }

                    chatsService.sendMessage(this.roomInfo.id, message, 1, lastMessageId).then(response => {
                        if (response.data.status) {
                            this.messages = [...response.data.messages, ...this.messages]
                            this.newMessage = ""
                            this.refreshLastMessageSent(this.roomInfo.id)
                        }
                        this.sendingMessage = false
                    })
                }
            },

            getRecentMessages() {
                let lastMessageId = null

                if (this.messages.length) {
                    lastMessageId = this.messages[0].id
                }

                chatsService.getRecentMessages(this.roomInfo.id, lastMessageId).then(response => {
                    if (response.data.length) {
                        if (this.messages[0].id === lastMessageId) {
                            this.messages = [...response.data, ...this.messages]

                            this.$emit('roomMessageAdded', this.roomInfo.id)
                            // this.roomInfo.unread = true
                        } else {
                            // need to think about way of processing
                        }
                    }
                })
            },
            closeRoom() {
                this.$emit('closeRoom', this.roomInfo.id)
            },
            minimizeRoom() {
                this.isMinimized = true
            },
            unMinimize() {
                this.isMinimized = false
            },
            getAvailableRecipients(search) {
                this.isLoading = true
                let selectedUsers = this.selectedRecipients.filter(recipient => {
                    return recipient.type === 'user'
                })
                chatsService.getAvailableRecipients(search, this.arrayHelper.arrayColumn(selectedUsers, 'id')).then(response => {
                    this.recipients = response.data
                    this.isLoading = false
                })
            },
            onContainerScroll(e) {
                if (!e.target) return

                const bottomScroll = this.getBottomScroll(e.target)
                if (bottomScroll >= this.loadMessagesTriggerPoint) {
                    this.loadMoreMessages()
                }
            },
            loadMoreMessages() {
                if (this.loadingMessages) return

                this.loadingMessages = true
                chatsService.getMessages(this.roomInfo.id, this.messages.length).then(response => {
                    if (response.data.length) {
                        this.messages = [...this.messages, ...response.data]
                    }
                    setTimeout(() => {
                        this.loadingMessages = false
                    }, 70)
                })
            },
            getBottomScroll(element) {
                const {scrollTop} = element
                return Math.abs(scrollTop)
            },
            getTopScroll(element) {
                const {scrollHeight, clientHeight} = element
                return scrollHeight - clientHeight
            },
            removeRecipient(option) {
                this.selectedRecipients = this.selectedRecipients.filter((tag) => tag.id !== option.id);
                this.$emit('update:tags', this.selectedRecipients);
            },
            markAsRead() {
                if (this.roomInfo.unread) {
                    this.$emit('readMessages', this.roomInfo.id)
                }
            },
            leaveChat() {
                chatsService.leaveChat(this.roomInfo.id).then(response => {
                    if (response.data) {
                        this.$emit('leftChat', this.roomInfo.id)
                    }
                })
            },
            leaveChatModalHide() {
                this.$bvModal.hide('modal-leave-chat');
            },
            enableManageMode() {
                if (this.canManageUsers) {
                    this.isManageChatRoomMode = true
                }
            },
            disableManageMode() {
                if (this.canManageUsers) {
                    this.isManageChatRoomMode = false
                }
            },
            saveChatUpdates() {
                let data = this.getSelectedRecipientsGroupByTypes()
                this.manageChat(this.roomInfo.id, data)
                this.disableManageMode()
            },
            getFormattedMembers(members) {
                return members.map(member => {
                    return {
                        id: member.user_id,
                        name: member.name,
                        type: member.type,
                    }
                });
            },
            duplicateChatsModalShow() {
                this.$bvModal.show('modal-duplicate-chats');
            },
            duplicateChatsModalHide() {
                this.$bvModal.hide('modal-duplicate-chats');
            },
            addRecipientToThisChat() {
                let data = this.getSelectedRecipientsGroupByTypes()
                this.manageChat(this.roomInfo.id, data)
                this.duplicateChatsModalHide()
            },
            openMatchedChat() {
                this.disableManageMode()

                this.$emit('updateRoom', this.roomInfo.id, this.matchedChatRoom)
            }
        }
    }
</script>

<style scoped>
    .chat-room-content .messages__info {
        /*   obsolete???     */
        margin-top: auto;
        padding: 14px;
        width: 100%;
        background: #f2f2f2;
        font-size: 14px;
        font-weight: normal;
        line-height: 24px;
        text-align: center;
        color: #3b3b3b;
    }
    .hide {
        display: none;
    }
</style>
